<template>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <van-list
      v-model="loading"
      class="list"
      :finished="finished"
      :finished-text="finishedText"
      offset="50"
      @load="onLoad"
    >
      <div class="ul">
        <slot name="before"></slot>
        <div v-for="(item, index) in list" :key="index" class="item">
          <slot :item="item" :index="index" />
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
</template>
<script>
import { i18n } from '@/main'
export default {
  components: {},
  props: {
    pageSize: {
      type: Number,
      default: 20,
    },
    fetchApi: {
      type: Function,
      default() {},
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    dataKey:{
      type: String,
      default:'dataList'
    },
    totalKey:{
      type: String,
      default:'totalCount'
    },
    finishedText: {
      type: String,
      default: i18n.t('没有更多了')
    },
  },
  data() {
    const that = this
    return {
      page: {
        currentPage: 1,
        pageSize: that.pageSize,
      },
      list: [],
      loading: false,
      finished: false,
      totalCount: 0,
      isLoading: false,
    }
  },
  provide() {
    return {
      sendGrandSon: this.sendGrandSon
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    sendGrandSon(val) {
      this.list = this.list.filter(({ id }) => (id != val))
    },
    resetting() {
      this.page.currentPage = 1
      this.list = []
      this.getList()
    },
    async getList() {
      const { page } = this
      const res = await this.fetchApi(page)
      if (!res) {
        this.finished = true
        return true
      }
      this.loading = false
      if (page.currentPage === 1) {
        this.list = res[this.dataKey]
      } else {
        this.list = this.list.concat(res[this.dataKey])
      }
      this.totalCount = res.page[this.totalKey]
      if ((page.currentPage*page.pageSize) >= this.totalCount) {
        this.finished = true
      } else {
        this.finished = false
      }
    },
    onLoad() {
      const { page } = this
      if ((page.currentPage*page.pageSize) < this.totalCount && this.totalCount !== 0) {
        this.page.currentPage++
        this.getList()
      }
    },
    onRefresh() {
      this.isLoading = false
      this.loading = true
      this.resetting()
    },
  },
}
</script>
<style lang="less" scoped>
.list {
  padding-bottom: 30px;
}
.ul {
  display: flex;
  flex-direction: column;
  // grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  // grid-row-gap: 16px;
  // grid-column-gap: 16px;
  padding: 10px 16px;
  .item:not(:last-of-type){
    margin-bottom: 16px;
  }
}
</style>
